<template>
  <div class="appscreen profile-form">
    <div style="display: none;">{{this.$store.state.lookedAtUser}}</div>
    <div class="">
      <div class="card">
        <div class="card-content">
          <div
            v-if="validationErrors.length"
            class="notification is-danger is-light"
          >
            <button @click="resetError()" class="btn delete"></button>
            <div class="content">
              Please resolve the following error(s) before proceeding.
              <ul style="margin-top:0.3em; margin-left: 1em">
                <li
                  v-for="(error, index) in validationErrors"
                  :key="`error-${index}`"
                  v-html="error"
                />
              </ul>
            </div>
          </div>
          <header>
          <!-- h1>Profil</h1 -->
          </header>
          <div class="form-container">
          <form>
            <div class="field">
              <label class="label">Numéro de téléphone</label>
              <div class="control">
                <input
                  v-model="phone"
                  class="input"
                  type="text"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Prénom, Nom</label>
              <div class="control">
                <input
                  v-model="name"
                  class="input"
                  type="text"
                  placeholder="Jean Durand"
                />
              </div>
            </div>
            <div class="field checkbox">
              <label class="label">Sexe</label>
              <!-- div>
              <input type="radio" id="one" v-model="gender" value="m">
              <label for="one">Homme <img class="icon" src="@/assets/icons/male.svg" /></label>
              <input type="radio" id="two" v-model="gender" value="f">
              <label for="two">Femme <img class="icon" src="@/assets/icons/female.svg" /></label>
              </div -->
              <span class="buttons radio">
                <button :class="gender=='m'?'btn gold':'btn'" :disabled="gender=='m'" @click.prevent="gender='m'">Homme&nbsp;<img class="icon" src="@/assets/icons/male.svg" /></button>
                <button :class="gender=='f'?'btn gold':'btn'" :disabled="gender=='f'" @click.prevent="gender='f'">Femme&nbsp;<img class="icon" src="@/assets/icons/female.svg" /></button>
              </span>
            </div>
            <div class="field">
              <label class="label">Date de naissance</label>
              <div class="control">
                <input
                  v-model="birthDate"
                  class="input"
                  type="date"
                  :max="new Date(getServerTime.getTime() - (5840) * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Taille (cm)</label>
              <div class="control">
                <input
                  v-model="height"
                  class="input"
                  type="text"
                />
              </div>
            </div>
                <input
                  v-model="credits"
                  class="input"
                  type="hidden"
                />
            <div class="field submit">
              <p class="control">
                <button @click.prevent="validate()" class="btn button is-success">
                  Enregistrer
                </button>
              </p>

            </div>
            <p class="control">
            <a href="#" @click.prevent="signOut">
              Se déconnecter <img class="icon" src="@/assets/icons/signout_.svg" />
            </a>
            </p>

          </form>
          </div>
          <div v-if="isAdmin && role==3 && type=='account'">
            <button @click.prevent="removeUser" class="btn delete">
              <img class="icon" src="@/assets/icons/plus_.svg" /> Effacer le client
            </button>
          </div>

          <div>
          <button @click.prevent="appUpdate()" class="btn button">
            Mettre à jour
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import firebase from "firebase/compat/app";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["user", "phonen"],
  data() {
    return {
      graphs: {
        bodyfat: { name: "Body fat", id: "bf", min:0, max: 50 },
        bodywater: { name: "Body water", id: "bw", min:0, max: 50 },
        musclemass: { name: "Muscle mass", id: "mm", min:0, max: 50 },
        weight: { name: "Weight", id: "w", min:0,max: 120 }
      },
      currentGraph: 'bodyfat',
      currentUser: null,
      name: null,
      email: null,
      phone: null,
      gender: null,
      height: null,
      role: null,
      type: null,
      credits: 0,
      birthDate: null,
      lastLoginAt: '',
      createdAt: '',
      stats: [],
      validationErrors: [],
      uid: null
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'isUserAuth', 'isAdmin','getServerTime']),
    Measurements() {
      const measurements = [];
      if(this.$store.state.userMeasurements==null) {
        return measurements;
      } else {
      const ordered = Object.keys(this.$store.state.userMeasurements).sort().reduce(
        (obj, key) => {
          obj[key] = this.$store.state.userMeasurements[key];
          return obj;
        },
        {}
      );
      return ordered;
      }
    }
  },
  methods: {

    ...mapActions(["signOutAction"]),

    appUpdate() {
        if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.update()
          }
          alert('updated');
        });
      } else {
        alert('up to date');
      }
    },
    signOut() {
      this.signOutAction();
      setTimeout(()=>{
        window.location.href = "/";
      });
    },
    removeUser() {
      if (window.confirm("Effacer le client : " + this.name + " (" +this.uid + ") ?")) {
        this.$store.dispatch("removeUser", this.uid);
        setTimeout(()=>{this.$router.push('/users')},1000);
      }
    },
    editUser() {
      this.isEditing = true;
    },
    updateUser() {
      this.isEditing = false;
      console.log('update');
    },
   resetError() {
     this.validationErrors = [];
   },
   polyline() {
     /* const stats = ["bf", "bw", "mm", "w"] */
     const container = [];

     for(let graph in this.graphs) {
       const poly = [];
       let i = 0;
       poly.push('50,400');
       if(typeof this.Measurements != "undefined")
       for(let measurement in this.Measurements) {

         const x = 50 + 640/(Object.keys(this.Measurements).length-1) * i++
         const y = 400 - (400/this.graphs[graph].max)*this.Measurements[measurement][this.graphs[graph].id]
         poly.push(x + "," + y);

       }
       poly.push('690,400');

       container[this.graphs[graph].id] = poly;
     }

     return container;
   },
  validate() {
    // Clear the errors befo$re we validate again
    this.resetError();

    /*
    // email validation
    if (!this.email) {
      this.validationErrors.push("<strong>E-mail</strong> cannot be empty.");
    }
    if (/.+@.+/.test(this.email) != true) {
      this.validationErrors.push("<strong>E-mail</strong> must be valid.");
    }
    // password validation

    if (!this.password) {
      this.validationErrors.push("<strong>Password</strong> cannot be empty");
    }
    if (/.{6,}/.test(this.password) != true) {
      this.validationErrors.push(
        "<strong>Password</strong> must be at least 6 characters long"
      );
    }

    if (!(this.password === this.passwordRepeat)) {
      this.validationErrors.push("<strong>Passwords</strong> did not match");
    }
    */

    // when valid then sign in
    if (this.validationErrors.length <= 0) {

        let user = firebase.auth().currentUser.uid;
        if(this.uid){
        console.log(this.uid)
          user = this.uid
        }
        let dateTime = 0
        console.log(this.birthDate)
        if(this.birthDate != null)
           dateTime = new Date(this.birthDate).getTime();
        const upUser = {};
        console.log('1')
        upUser.uid = user;
        console.log('2')
        upUser.displayName = this.name
        upUser.phoneNumber = this.phone
        upUser.gender = this.gender
        upUser.height = this.height
        upUser.credits = this.credits
        upUser.creditsD = this.creditsD
        if(typeof upUser.creditsD == "undefined") 
          upUser.creditsD = null;
        upUser.phoneNumber = this.phone
        upUser.birthDate = {seconds : dateTime/1000, nanoseconds: 0}
        upUser.stats = this.stats
        console.log('3',upUser)

        if(!isNaN(upUser.uid)) {
          this.$store.dispatch("updateAccount", upUser);
          setTimeout(()=>{this.$router.push('/users')},1000);
        } else {
          this.$store.dispatch("updateUser", upUser);
          if(this.phonen!=null) {
            setTimeout(()=>{this.$emit('updated');},1000);
            setTimeout(()=>{window.location.reload(); },1300);
          }
        }
      }
    }
  },
  updated() {
    if(this.currentUser == null && this.$store.state.lookedAtUser !=null) {
    const currentUser = this.$store.state.lookedAtUser;
    this.currentUser = currentUser;
    if(currentUser != null) {
      this.name = currentUser.displayName,
      this.email = currentUser.email;
      this.phone = currentUser.phoneNumber;
      if(typeof currentUser.birthDate != "undefined") {
        this.gender = currentUser.gender;
        this.credits = currentUser.credits;
        this.creditsD = currentUser.creditsD;
        this.height = currentUser.height;
        this.role = currentUser.role;
        this.type = isNaN(currentUser.id)?'user':'account';
        const parsedTimestamp = new Date(currentUser.birthDate.seconds * 1000);
        const month = parsedTimestamp.getMonth()+1;
        const date = parsedTimestamp.getDate();
        this.birthDate =   parsedTimestamp.getFullYear()+ '-' + month.toString().padStart(2,0) + '-' + date.toString().padStart(2, '0');
        this.stats = currentUser.stats;
      }
    }
    }
  },
  mounted() {

    if(this.currentUser == null && this.$store.state.lookedAtUser !=null) {
    const currentUser = this.$store.state.lookedAtUser;
    this.currentUser = currentUser;
    if(currentUser != null) {
      this.name = currentUser.displayName,
      this.email = currentUser.email;
      this.phone = currentUser.phoneNumber;
      if(typeof currentUser.birthDate != "undefined") {
        this.gender = currentUser.gender;
        this.credits = currentUser.credits;
        this.creditsD = currentUser.creditsD;
        this.height = currentUser.height;
        this.role = currentUser.role;
        this.type = isNaN(currentUser.id)?'user':'account';
        const parsedTimestamp = new Date(currentUser.birthDate.seconds * 1000);
        const month = parsedTimestamp.getMonth()+1;
        const date = parsedTimestamp.getDate();
        this.birthDate =   parsedTimestamp.getFullYear()+ '-' + month.toString().padStart(2,0) + '-' + date.toString().padStart(2, '0');
        this.stats = currentUser.stats;
      }
    }
    }
  },
  created() {

    if(this.phonen!=null) this.phone = this.phonen;

    if(typeof this.user == "undefined") {

      if(typeof this.$route.params.uid != "undefined")
        this.uid = this.$route.params.uid
      else
        if(this.getCurrentUser.uid!=null)
          this.uid = this.getCurrentUser.uid;


    } else this.uid = this.user;

    this.$store.dispatch("bindUser", {user : this.uid});
    this.$store.dispatch("bindMeasurements", {user : this.uid});
  }
};
</script>
