<template>
  <div class="appscreen home">
    <div class="is-admin" v-if="isAdmin||isManager">
      <header>

       <CoachSwitch :currentCoach="currentCoach" @switchCoach="switchCoach"></CoachSwitch>

       <div class="actions buttons radio">
         <button class="btn" @click.prevent="lastWeek">&#60;</button>
         <span v-if="isAdmin">Semaine du {{short(selectedWeek)}} • {{hoursCount}}h</span>
         <span v-else>Semaine du {{short(selectedWeek)}}</span>
         <button class="btn" @click.prevent="nextWeek">&#62;</button>
       </div>

      </header>
      <div class="days">
        <Week @book="toggleModal" :day="selectedWeek" :key="weekupdate" @count="incrementHours" :coach="currentCoach"/>
      </div>
    </div>
    <div class="is-user" v-else>
        <div class="days weekcalendar" v-if="week.today">
        </div>


        <div class="user-head" v-if="!!this.$store.state.lookedAtUser">

        </div>
               <div class="disclaimer" v-if="this.$store.state.config && this.$store.state.config.disclaimer">
                {{this.$store.state.config.disclaimer}}
                <div class="disclaimer-signature">- L'équipe de L'endroit Reims</div>
               </div>
        <div class="actions" v-if="!!this.$store.state.lookedAtUser">

        <div class="cta-container">

          <div>
            <router-link :to="'/booking/'+getCurrentUser.uid+ '/1'" :class="'book btn test '+(this.$store.state.lookedAtUser.credits>0?'':' disabled')">
                Réserver une séance
            </router-link>
            <div class="passes credits" v-if="this.$store.state.lookedAtUser.credits>0">
              <div class="pass"><img class="icon" src="@/assets/icons/coin_.svg" />
              {{this.$store.state.lookedAtUser.credits + userBookingsLength}}
              </div>
              <div class="pass used"><img class="icon" src="@/assets/icons/coin_.svg" />
              {{userBookingsLength}}
              </div>
            </div>
          </div>

          <div>
            <router-link :to="'/booking/'+getCurrentUser.uid + '/2'" :class="'book btn test '+(this.$store.state.lookedAtUser.creditsD>0?'':' disabled')">
                Réserver une séance duo
            </router-link>
            <div class="passes credits" v-if="this.$store.state.lookedAtUser.credits>0">
              <div class="pass"><img class="icon" src="@/assets/icons/coind_.svg" />
              <span v-if="typeof this.$store.state.lookedAtUser.creditsD != 'undefined'">&nbsp;{{this.$store.state.lookedAtUser.creditsD + userBookingsDuoLength}}</span>
              <span v-else>&nbsp;0</span>
              </div>
              <div class="pass used"><img class="icon" src="@/assets/icons/coin_.svg" />
              <span v-if="typeof this.$store.state.lookedAtUser.creditsD != 'undefined'">&nbsp;{{userBookingsDuoLength}}</span>
              <span v-else>&nbsp;0</span>
              </div>
            </div>
          </div>

          </div>
          <div class="infos">
            Crédits   {{this.$store.state.lookedAtUser.credits + userBookingsLength}} <img class="icon" src="@/assets/icons/coin_.svg" />
             • Crédits en cours d'utilisation {{userBookingsLength}} <img class="icon" src="@/assets/icons/coin_.svg" />
             <span v-if="this.$store.state.lookedAtUser.creditsD">  <br /> Crédits Duo  {{this.$store.state.lookedAtUser.creditsD + userBookingsDuoLength}} <img class="icon" src="@/assets/icons/coind_.svg" />
              • Crédits DUO en cours d'utilisation {{userBookingsDuoLength}} <img class="icon" src="@/assets/icons/coind_.svg" /></span>
           </div>

       </div>


       <div v-if="!!this.$store.state.lookedAtUser" class="planning">
          <div v-if="someNotifications" class="user-notifications">
            <div v-for="(message, key) in userMessages" v-bind:key="message.id" class="user-notification">
              <div v-if="userMessages[key].text">
                <div v-if="userMessages[key].uid!='system'">{{Coachs[userMessages[key].uid].displayName.split(" ")[0]}}, le {{local(parseInt(key))}}</div>
                <div class="notification-message">"{{userMessages[key].text}}"</div>
              </div>
            </div>
          </div>
          <button @click.prevent="deleteMessages(this.$store.state.lookedAtUser.id)" v-if="someNotifications" class="delete-messages">x</button>
          <h2><span v-if="typeof this.$store.state.lookedAtUser.bookings !='undefined' && Object.keys(this.$store.state.lookedAtUser.bookings).length">
            Prochain<span v-if="typeof this.$store.state.lookedAtUser.bookings !='undefined' && Object.keys(this.$store.state.lookedAtUser.bookings).length>1">s</span> rendez-vous</span>
          </h2>
          <div v-for="(booking, key) in userBookings" v-bind:key="booking.id">
            <div v-for="(time, hour) in booksort(booking)" v-bind:key="time.id" class="userBooking">
              <h3>{{local(key)}}</h3>
              <div>{{hour}}
                <div v-for="rdv in time" v-bind:key="rdv.id">
                  <span>
                    <span v-if="rdv.group">Duo ( {{rdv.userComment}} )&nbsp;</span>
                    <span v-if="rdv.coach!=null && typeof Coachs[rdv.coach]!='undefined'">avec {{Coachs[rdv.coach].displayName.split(" ")[0]}}</span>
                    <span v-else></span>
                  </span>
                  <div v-if="rdv.managerComment" class="comment">{{rdv.managerComment}}</div>
                  <div class="passes">
                    <div class="pass" v-if="!rdv.group"><img class="icon" src="@/assets/icons/coin_.svg" /> 1</div>
                    <div class="pass" v-else><img class="icon" src="@/assets/icons/coin_.svg" /> 2</div>
                  </div>
                  <div><button @click.prevent="removeBooking(key,hour,rdv)" class="btn cancel" :disabled="!soon(key,hour)">x Annuler</button></div>
                  <div :class="(rdv.confirmed?'confirmed':'notconfirmed') + ' confirmation'">
                    <span v-if="rdv.confirmed">Confirmé</span><span v-else>Non confirmé</span> &nbsp;<span v-if="rdv.floor">• <span v-if="rdv.floor==1">En bas</span><span v-if="rdv.floor==3">Au milieu</span><span v-if="rdv.floor==2">En haut</span></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

       <div class="user-location">
       <p>1 Place Aristide Briand 51100 REIMS</p>
        <a href="https://www.google.com/maps/place/L'endroit+Reims/@49.2573445,4.0387795,15z/data=!4m6!3m5!1s0x47e975c24417974b:0x24d30a7ebaca3330!8m2!3d49.2573445!4d4.0387795!16s%2Fg%2F11hyz6bbzw">
          Se rendre à L'endroit
        </a><br /><br />
        <a href="tel:+33766858763" class="btn phone"><img class="icon" src="@/assets/icons/telephone_.svg" /><span> Appeler L'endroit Reims</span></a>
       </div>

       <div class="user-footer">
        <p>Copyright &copy; 2023 L'endroit • Reims</p>
       </div>
    </div>

    <div class="calendar-footer">
      
    <div>
      <div v-if="checkVacancy(this.$store.state.bookings[new Date().getDay()-1])">
        [En haut] <span v-if="
          vacancy(this.$store.state.bookings[new Date().getDay()-1], 2) 
          && vacancy(this.$store.state.bookings[new Date().getDay()-1], 2) 
          && Coachs[vacancy(this.$store.state.bookings[new Date().getDay()-1], 2).coach]">
          Occupé
        </span><span v-else>Libre</span>
        <br />[Au milieu] <span v-if="
        vacancy(this.$store.state.bookings[new Date().getDay()-1], 3)
        && vacancy(this.$store.state.bookings[new Date().getDay()-1], 3).coach 
        && Coachs[vacancy(this.$store.state.bookings[new Date().getDay()-1], 3).coach]">
        Occupé
      </span><span v-else>Libre</span>
        <br />[En bas] <span v-if="
        vacancy(this.$store.state.bookings[new Date().getDay()-1], 1)
        && vacancy(this.$store.state.bookings[new Date().getDay()-1], 1).coach 
        && Coachs[vacancy(this.$store.state.bookings[new Date().getDay()-1], 1).coach]">
        Occupé
      </span><span v-else>Libre</span>
      </div>
      </div>
      <div v-if="isAdmin" >
        <!-- a href="#" @click.prevent="reindexDay">Index</a>&nbsp;|&nbsp; -->
        <router-link to="/settings">Paramètres</router-link>&nbsp;|&nbsp;
        <router-link to="/calendar">Règlages</router-link>
      </div>
    </div>


   <div id="bookingModal" v-if="modalOpened" class="modal" @click="modalclick">
    <div>
      <a id="close" href="#" @click.prevent="modalclose" class="close">[X]S</a>
      <Booking :day="bookingDay" :time="bookingHour" :user="bookingUser" :coach="bookingCoach" :currentCoach="currentCoach" :confirmed="bookingConfirm" :title="bookingTitle" :note="bookingNote" :color="bookingColor" :floor="bookingFloor" :group="bookingGroup" :user2="bookingUser2" :userComment="bookingUserComment" :managerComment="bookingManagerComment" :created="bookingCreated" :tlength="bookingLength"
      @booking="toggleDateModal"
      :userMark="bookingUserMark" :user2Mark="bookingUser2Mark"
      />
    </div>
   </div>
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from "vuex";

import Week from "../components/WeekVue.vue";
import Booking from "../components/BookingVue.vue";
import CoachSwitch from "../components/CoachSwitch.vue";


export default {
  name: "HomePage",
  components: {
//    Day,
    Week,
    Booking,
    CoachSwitch 
  },
  data() {
    return {
      hoursCount: 0,
      booking: {
        date: "",
        time: "",
        booking: "",
        user: null,
        color: null,
        title: null,
        managerComment: null,
        note: null,
        coach: null
      },
      user: {
        firstName: "",
        lastName: "",
        credit: "",
        gender: "",
        height: ""
      },
      week: {
        last: new Date( (new Date()).getTime() - 14 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
        today: new Date().toISOString().slice(0, 10),
        next: new Date( (new Date()).getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
        nextnext: new Date( (new Date()).getTime() + 14 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
        nextnextnext: new Date( (new Date()).getTime() + 21 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
      },
      selectedWeek:
      (
        (new Date().getDay()==0)?
          new Date( (new Date()).getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10):
          new Date().toISOString().slice(0, 10)
      ),
      selector: 0,
      weekupdate: 0,
      uid: null,
      modalOpened: false,
      today: new Date().toISOString().slice(0, 10),
      bookingHour: null,
      bookingDay: null,
      bookingCoach: null,
      bookingConfirm: null,
      bookingUser: null,
      bookingUser2: null,
      bookingUserMark: null,
      bookingUser2Mark: null,
      bookingUserComment: null,
      bookingManagerComment: null,
      bookingLength: 60,
      bookingGroup: null,
      bookingFloor: null,
      bookingColor: "blue",
      bookingTitle: null,
      bookingCreated: null,
      bookingNote: null,
      currentCoach: null
    };
  },
  computed: {
    ...mapGetters(['isUserAuth', 'isAdmin', 'isTopManager', 'isManager', 'getCurrentUser', 'getServerTime', 'getServerTimeSeconds', 'someNotifications']),
    Bookings() {
      return this.$store.state.bookings;
    },
    /*
    TodaysBookings() {
      const today = new Date(this.getServerTime).toISOString();
      const hour = parseInt(today.slice(11,13)) + 1;
      const minute = today.slice(14,16);

      const bookings = this.$store.state.daysBookings;
      let result = {};

      for(let time in bookings) {
        if(typeof bookings[time].nanoseconds == "undefined")
          for(let coach in bookings[time]) {
            if(bookings[time][coach].floor != null) {
              
              const hourB = bookings[time][coach].time.slice(0,2); 
              const minuteB = bookings[time][coach].time.slice(3,5);
              const current = parseInt(hour) * 100 + parseInt(minute);
              const start = parseInt(hourB) * 100 + parseInt(minuteB);
              const end = (parseInt(hourB) + parseInt(bookings[time][coach].tlength)/60) * 100 + (parseInt(minuteB) + parseInt(bookings[time][coach].tlength)%60);
              if(start <= current && current <=  end){
                result[bookings[time][coach].floor] = bookings[time][coach];
              }
            }
          }
        }

      return result;
      //} else return null;
    },*/
    userMessages() {
        if(typeof this.$store.state.lookedAtUser.notifications != "undefined" && this.$store.state.lookedAtUser.notifications != null) {

          const messages = this.$store.state.lookedAtUser.notifications;
          const today = this.getServerTime;
          today.setHours(0,0,0,0);

          
          const ordered = Object.keys(messages).sort().reduce(
            (obj, key) => {
                if(typeof obj != 'undefined' && typeof messages != 'undefined')
                obj[key] = messages[key];
              return obj;

            },
            {}
          );
          const result = {};
          for(let i in ordered) {
            if(ordered[i]!=null)
              result[i] = ordered[i];
          }



          return result;

        }

      return true;
    },
    userBookings() {
        if(typeof this.$store.state.lookedAtBookings != "undefined" && this.$store.state.lookedAtBookings != null) {

          let bookings = this.$store.state.lookedAtBookings;

          for(let bdate in bookings) {
            for(let time in bookings[bdate]) {
              for(let coach in bookings[bdate][time]) {


                if( bookings[bdate][time][coach].user!=this.getCurrentUser.uid && bookings[bdate][time][coach].user2 !=this.getCurrentUser.uid)
                  delete bookings[bdate][time][coach];
              }
              if(Object.keys(bookings[bdate][time]).length==0) {
                delete bookings[bdate][time];
              }
            }
          }
            
          for (let i in bookings) {
            if(Object.keys(bookings[i]).length==0) {
              delete bookings[i];
            }
          }

          let date = null;
          const today = this.getServerTime;
          today.setHours(0,0,0,0);

          const ordered = Object.keys(bookings).sort().reduce(
            (obj, key) => {
              date = new Date(key);

              date.setHours(0,0,0,0);
              if(typeof obj != 'undefined' && typeof bookings != 'undefined')
              if(date >= today){
                obj[key] = bookings[key];
              } else {
              obj[key] = null;
              }
              return obj;

            },
            {}
          );
          
          const result = {};
          for(let i in ordered) {
            if(ordered[i]!=null)
              result[i] = ordered[i];
          }



          return result;
          
        }

      return true;
    },


    userBookingsLength() {

        let length = 0;
        if(typeof this.$store.state.lookedAtBookings != "undefined" && this.$store.state.lookedAtBookings != null) {

          const bookings = this.$store.state.lookedAtBookings;
          let date = null;
          let count = 0;
          const today = this.getServerTime;
          today.setHours(0,0,0,0);

        
          const ordered = Object.keys(bookings).sort().reduce(
            (obj, key) => {
              date = new Date(key);
              date.setHours(0,0,0,0);
              if(typeof obj != 'undefined' && typeof bookings != 'undefined')
              if(date >= today){
                obj[key] = bookings[key];
                  for (let coach in bookings[key]){
                    for(let b in bookings[key][coach]) {
                      if(bookings[key][coach][b].group == null || !bookings[key][coach][b].group)
                        count++;
                    }
                  }
                    length = length + Object.keys(bookings[key]).length;
              } else {
              obj[key] = null;
              }
              return obj;

            },
            {}
          );
          const result = {};
          for(let i in ordered) {
            if(ordered[i]!=null) {
              result[i] = ordered[i];
              }

          }

          /* return length; */
          return count;

        }

      return 0;
    },

    userBookingsDuoLength() {

        let length = 0;
        if(typeof this.$store.state.lookedAtBookings != "undefined" && this.$store.state.lookedAtBookings != null) {

          const bookings = this.$store.state.lookedAtBookings;
          let date = null;
          let count = 0;
          const today = this.getServerTime;
          today.setHours(0,0,0,0);
          const ordered = Object.keys(bookings).sort().reduce(
            (obj, key) => {
              date = new Date(key);
              date.setHours(0,0,0,0);
              if(date >= today){
                obj[key] = bookings[key];
                  for (let coach in bookings[key]){
                    if(typeof bookings[key][coach] != "undefined")
                    for(let b in bookings[key][coach]) {
                      if(bookings[key][coach][b].group != null && bookings[key][coach][b].group)
                        count++;
                    }
                  }

                    length = length + Object.keys(bookings[key]).length;
              } else {
              obj[key] = null;
              }
              return obj;

            },
            {}
          );
          const result = {};
          for(let i in ordered) {
            if(ordered[i]!=null) {
              result[i] = ordered[i];
              }

          }

          /* return length; */
          return count;

        }

      return 0;
    },
    userPastBookings() {
        if(typeof this.$store.state.lookedAtBookings != "undefined" && this.$store.state.lookedAtBookings != null) {


          const initBookings = this.$store.state.lookedAtBookings;
          const bookings = {};

          for (let key in initBookings) {
            for(let bkey in initBookings[key]) {
              const hour = {};
              hour[bkey] = initBookings[key][bkey];
              bookings[key] = hour;
            }
          }
          /*

          for (let key in logCredits) {
            for(let bkey in logCredits[key]) {
              const hour = {};
              if(logCredits[key][bkey]>0)
              hour[bkey] = '+ ' + logCredits[key][bkey];
              else
              hour[bkey] = logCredits[key][bkey];
              bookings[key] = hour;
            }
          }
          */

          let date = null;
          const today = this.getServerTime;
          today.setHours(0,0,0,0);

          const ordered = Object.keys(bookings).sort().reverse().reduce(
            (obj, key) => {
              date = new Date(key);
              date.setHours(0,0,0,0);
              if(date <= today){
                obj[key] = bookings[key];
              } else {
              obj[key] = null;
              }
              return obj;

            },
            {}
          );

          const result = {};
          for(let i in ordered) {
            if(ordered[i]!=null)
              result[i] = ordered[i];
          }

          return result;

        }

      return true;
    },
    weekTotal() {
      return false;
    },
    Coachs() {

      if(this.$store.state.config!=null) {
      const ordered = Object.keys(this.$store.state.config.team).sort().reduce(
        (obj, key) => {
          if(typeof obj != 'undefined' && typeof this.$store.state.config.team != 'undefined')
          obj[key] = this.$store.state.config.team[key];
          return obj;
        },
        {}
      );

      return ordered;
      } return {};
    },
  },
  methods: {
   getDates(startDate, stopDate) {
      var dateArray = new Array();
      var currentDate = startDate;
      while (currentDate <= stopDate) {
        currentDate.setDate(currentDate.getDate() + 1);
        dateArray.push(currentDate)
      }

      return dateArray;
    },
    checkVacancy(dayBookings) {
      if(typeof dayBookings!="undefined" && dayBookings!=null)
      return new Date().toISOString().slice(0,10) == new Date(dayBookings.date.seconds*1000).toISOString().slice(0,10);
      else return false;
    },
    vacancy(daysBookings,floor) {
      const today = new Date(this.getServerTime).toISOString();
      const hour = parseInt(today.slice(11,13)) + 1;
      const minute = today.slice(14,16);

      const bookings = daysBookings;
      let result = {};

      for(let time in bookings) {
        if(typeof bookings[time].nanoseconds == "undefined")
          for(let coach in bookings[time]) {
            if(bookings[time][coach].floor != null) {
              
              const hourB = bookings[time][coach].time.slice(0,2); 
              const minuteB = bookings[time][coach].time.slice(3,5);
              const current = parseInt(hour) * 100 + parseInt(minute);
              const start = parseInt(hourB) * 100 + parseInt(minuteB);
              const end = (parseInt(hourB) + parseInt(bookings[time][coach].tlength)/60) * 100 + (parseInt(minuteB) + parseInt(bookings[time][coach].tlength)%60);
              if(start <= current && current <=  end){
                result[bookings[time][coach].floor] = bookings[time][coach];
              }
            }
          }
        }
      if(typeof result[floor]!="undefined")
      return result[floor];
      else return null;
    },
    reindexDay() {

      console.log('reindex');

      // this.$store.dispatch("reindexBookingDay", {day:'2025-01-23'});
      this.$store.dispatch("reindexUserBookings", {day:'2025-03-30', user: 'lzRxoW4xumQjhSr8VJVQY5z96LD2'});


    },  
    soon(date, time) {

      const now = new Date(this.getServerTimeSeconds * 1000);
      const then = new Date(date+'T'+time+':00.000');
      const diff = then.getTime() - now.getTime();

      let check = false;
      if (diff > 60*60*24*1000) check = true;
      return check;

    },
    booksort(bookings) {

      const ordered = Object.keys(bookings).sort().reduce(
        (obj, key) => {
          if(typeof obj != 'undefined' && typeof bookings !='undefined')
          obj[key] = bookings[key];
          return obj;
        },
        {}
      );

      return ordered;
    },
    modalclick(event) {

      // not great, not terrible
      setTimeout(()=>{
          this.weekupdate = this.weekupdate + 1;
      },1000);

      if(event.target.classList.contains('modal'))
        this.toggleDateModal();
    },
    modalclose() {

      this.modalOpened = false;
      this.clearModal();


      setTimeout(()=>{
          this.weekupdate = this.weekupdate + 1;
      },1000);

    },
    incrementHours(data) {
      this.hoursCount = parseInt(data);
    },
    deleteMessages(key) {
      if(confirm("Supprimer les messages ?")) {
        let messages = {};
        messages.user = key;
       this.$store.dispatch("deleteMessages", messages);
       }
    },
    switchCoach(data) {
      this.currentCoach = data;
    //  this.$store.dispatch("bindTeamBookings", {coach : data});
      this.weekupdate = this.weekupdate + 1;
    },
    toggleModal(data) {


      this.bookingDay = data.day;

      if(typeof data.coach !="undefined") {
        this.booking.coach = data.coach
        this.bookingCoach = data.coach
      }

      if(typeof data.title !="undefined" && data.title!==true) {
        this.bookingTitle = data.title
        this.booking.Title = data.title
        }

        if(typeof data.created !="undefined" ) {
        this.bookingCreated = data.created
        this.booking.created = data.created
        }

      if(typeof data.note !="undefined" && data.note!==true) {
        this.bookingNote = data.note
        this.booking.Note = data.note
      }

      if(typeof data.color !="undefined" && data.color!==true) {
        this.bookingColor = data.color
        this.booking.color = data.color
      }


      if(typeof data.floor !="undefined") {
        this.bookingFloor = data.floor
        this.booking.floor = data.floor
      }

      if(typeof data.group !="undefined") {
        this.bookingGroup = data.group
        this.booking.group = data.group
      }

      if(typeof data.booking !="undefined" && data.booking!==true) {
        this.bookingUser = data.booking
        this.booking.user = data.booking
      }

      if(typeof data.user2 !="undefined" && data.user2!==true) {
        this.bookingUser2 = data.user2
        this.booking.user2 = data.user2
      }

      if(typeof data.userMark !="undefined" && data.userMark!==true) {
        this.bookingUserMark = data.userMark
        this.booking.userMark = data.userMark
      }

      if(typeof data.user2Mark !="undefined" && data.user2Mark!==true) {
        this.bookingUser2Mark = data.user2Mark
        this.booking.user2Mark = data.user2Mark
      }


      if(typeof data.userComment !="undefined") {
        this.bookingUserComment = data.userComment
        this.booking.userComment = data.userComment
      }

      if(typeof data.managerComment !="undefined") {
        this.bookingManagerComment = data.managerComment
        this.booking.managerComment = data.managerComment
      }

      if(typeof data.tlength !="undefined") {
        this.bookingLength = data.tlength
        this.booking.length = data.tlength
      }

      if(typeof data.confirmed !="undefined") {
        this.bookingConfirm = data.confirmed
        this.booking.confirmed = data.confirmed
      }

      this.booking.date = data.day;
      this.bookingHour = data.hour;
      this.booking.time = data.hour;
      this.modalOpened = !this.modalOpened;

      /* not great, not terrible */
      setTimeout(()=>{
          this.weekupdate = this.weekupdate + 1;
      },1000);

    },

    lastWeek() {
      this.selector--;

      this.selectedWeek = ((this.getServerTime.getDay()==0)?new Date( (this.getServerTime).getTime() + 1 + 7 * parseInt(this.selector) * 24 * 60 * 60 * 1000).toISOString().slice(0, 10):new Date( (this.getServerTime).getTime() + 7 * parseInt(this.selector) * 24 * 60 * 60 * 1000).toISOString().slice(0, 10));

      var curr = new Date(this.selectedWeek); // get current date

      let first  = new Date(curr.toUTCString());
      let plusone = false;
      if(first.getDay()==0) {
        plusone =true;
        first.setDate(first.getDate() + 1);
        }
      first.setDate(first.getDate() - ((first.getDay())) % 7);
      let last  = new Date(curr.toUTCString());
      if(last.getDay()==0)
        last.setDate(last.getDate() + 1);
        if(plusone)
      last.setDate(last.getDate() +1+ ((7-last.getDay())) % 7);
      else
      last.setDate(last.getDate() + ((7-last.getDay())) % 7);

      this.$store.dispatch("bindBookings", {start: first, end: last});

      /* not great, not terrible */
      setTimeout(()=>{
          this.weekupdate = this.weekupdate + 1;
      },1000);

      return true;
    },

    nextWeek() {

      this.selector++;
        this.selectedWeek = ((this.getServerTime.getDay()==0)?new Date( (this.getServerTime).getTime() + 1* 24 * 60 * 60 * 1000 + 7 * parseInt(this.selector) * 24 * 60 * 60 * 1000).toISOString().slice(0, 10):new Date( (this.getServerTime).getTime() + 7 * parseInt(this.selector) * 24 * 60 * 60 * 1000).toISOString().slice(0, 10));

      var curr = new Date(this.selectedWeek); // get current date

      var firstday = new Date(curr.setDate( curr.getDate() - curr.getDay() )).toUTCString();
      var lastday = new Date(curr.setDate( curr.getDate() - curr.getDay() + 6 )).toUTCString();
      if(curr.getDay()==6) {
        firstday = new Date(curr.setDate( curr.getDate() + (1 - curr.getDay())  )).toUTCString();
        lastday = new Date(curr.setDate( curr.getDate() - curr.getDay() + 7  )).toUTCString();
      }
      this.$store.dispatch("bindBookings", {start: firstday, end: lastday});

      /* not great, not terrible */
      setTimeout(()=>{
          this.weekupdate = this.weekupdate + 1;
      },1000);


      return true;
    },

    local(day) {
      const event = new Date(day+'T12:00:00');
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return event.toLocaleDateString('fr-FR', options);
    },
    short(day) {
      const d = day.toString().substr(8,2) + "/" + day.toString().substr(5,2);
      return d
    },
    toggleDateModal() {
      this.modalOpened = !this.modalOpened;
      this.clearModal();
      /* not great, not terrible */
      setTimeout(()=>{
          this.weekupdate = this.weekupdate + 1;
      },1000);
    },
    clearModal() {
      this.bookingDay = null;
      this.bookingHour = null;
      this.bookingConfirm = null;
      this.bookingUser = null;
      this.bookingUser2 = null;
      this.bookingUserMark = null;
      this.bookingUser2Mark = null;
      this.bookingCreated = null;
      this.bookingUserComment = null;
      this.bookingManagerComment = null;
      this.bookingLength = 60;
      this.bookingTitle = null;
      this.bookingNote = null;
      this.bookingColor = "blue";
      this.bookingCoach = null;
      this.bookingFloor = null;
      this.bookingGroup = null;
      this.booking = {
        user: null,
        user2: null,
        date: null,
        time: null,
        title: null,
        note: null,
        color: null,
        coach: null,
        created: null,
        floor: null,
        group: null
      };
    },
    removeBooking(date,hour,rdv) {
      //this.$store.dispatch("bindConfig");

      if( confirm("Annuler ce rendez-vous ?")) {
        const booking = {};
        booking.user = rdv.user;
        booking.user2 = rdv.user2;
        booking.time = rdv.time.slice(0, 5);
        booking.date = date;
        booking.group = rdv.group;
        booking.coach = rdv.coach;


        if(!this.isAdmin && !this.isManager)  {
          let notifications = {};
          notifications.user = 'fOirURqqdgNj6mKYfWClWS5Z4Pz2'; /* this.booking.coach */
          const dateN = new Date(booking.date);
          const dateNF = [
            ('0' + dateN.getDate()).slice(-2),
            ('0' + (dateN.getMonth() + 1)).slice(-2)
          ].join('/');
          notifications.text = 'Annulation de demande de réservation ' + dateNF;
          notifications.uid = this.getCurrentUser.uid;
          this.$store.dispatch("addNotifications", notifications);
        }

        this.$store.dispatch("deleteBooking", booking);

        let logUser = rdv.user;
        let logUser2 = rdv.user2;
        let logAdmin = this.getCurrentUser.uid;
        let logCoach = rdv.coach;

        let logData  = {
          msg : "annulation de réservation " +rdv.date + " " + rdv.time,
          user : logUser,
          admin : logAdmin,
          user2 : logUser2,
          coach : logCoach,
          }
        this.$store.dispatch("log", logData);
        /* this.$store.dispatch("log", "annulation de réservation " + rdv.date + " " + rdv.time); */
        }

    },
    ...mapActions([
      'addCredit',
      'removeCredit'
    ]),
  },

  mounted() {

    console.log('mounted');
    /*
    const todayB = new Date().toISOString().slice(0,10);
    console.log('todayB', todayB);
    this.$store.dispatch("todaysBookings", {date: todayB});
    */
  },

  created() {

    console.log('created');

    this.week.last = new Date( (this.getServerTime).getTime() - 14 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
    this.week.today = this.getServerTime.toISOString().slice(0, 10);
    this.week.next = new Date( (this.getServerTime).getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
    this.week.nextnext = new Date( (this.getServerTime).getTime() + 14 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
    this.week.nextnextnext = new Date( (this.getServerTime).getTime() + 21 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);

    this.selectedWeek = (this.getServerTime.getDay()==0)?
      new Date( (this.getServerTime).getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10):
      this.getServerTime.toISOString().slice(0, 10);

    this.today = this.getServerTime.toISOString().slice(0, 10);


    const usertmp = {};
    usertmp['user'] = this.getCurrentUser.uid;

    if(this.isAdmin||this.isManager) this.$store.dispatch("bindUsersIndex");


    if(this.isAdmin) {
      let coach = null;
      if(this.getCurrentUser.uid != "fOirURqqdgNj6mKYfWClWS5Z4Pz2")
        coach = this.getCurrentUser.uid;
      else
        coach = 'UZgiD1IEymhtToWu9a3wqZTxv672';
      this.currentCoach = coach;
    }

    if(this.isManager) {
      let coach = null;
      if(this.getCurrentUser.uid != "fOirURqqdgNj6mKYfWClWS5Z4Pz2")
        coach = this.getCurrentUser.uid;
      else
        coach = 'orZGDb0BdpTz77AwrvjeajWppAa2';
      this.currentCoach = coach;
    }


    if(this.isAdmin || this.isManager) {
      this.$store.dispatch("bindBookings");
    }
    this.$store.dispatch("bindConfig");
    this.$store.dispatch("bindTimetable");


    this.$store.dispatch("bindUser", {user : this.getCurrentUser.uid});
    this.$store.dispatch("bindUserBookings", {user : this.getCurrentUser.uid});

  },
};
</script>
