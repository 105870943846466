<template>
  <div class="screen">
    <!-- Number Input Form -->
    <div id="recaptcha-container"></div>
    <div id="sign-in-container" v-if="showNumberInput">
      <div>
        <img alt="Vue logo" src="../assets/l-endroit_.svg">
        <div class="form-group">
          <input type="tel" class="form-control form-control-lg" v-model="numberInputForm.number" @keyup="isPhoneNumberValid" placeholder="Votre numéro de téléphone" required autocomplete="off">
        </div>
        <div class="form-group">
          <button @click.prevent="onSignInSubmit()" id="get-sign-in-code" class="btn btn-block btn-lg success theme-accent" disabled>{{ getSignInCodeButton.text }}</button>
        </div>
      </div>
    </div>

    <!-- SMS Verification Form -->
    <div id="sign-in-code-container" v-if="showCodeInput">
      <div>
        <img alt="Vue logo" src="../assets/l-endroit_.svg">
        <div class="form-group">
          <input type="tel" class="form-control form-control-lg" v-model="numberInputForm.code" placeholder="Code de vérification" required>
        </div>
        <div class="form-group">
          <button @click.prevent="onVerifyCodeSubmit()" id="send-verify-code" class="btn btn-block btn-lg success theme-accent">{{ signInButton.text  }}</button>
        </div>
      </div>
    </div>

    <div v-if="showNewUser" class="profile-signin">
      <Profile :user="user.uid" :phonen="user.phoneNumber" @updated="showNewUser=false" v-if="user" />
    </div>

  </div>
</template>

<script>
// https://github.com/firebase/quickstart-js/blob/master/auth/phone-invisible.html
// https://stackoverflow.com/questions/48322164/firebase-phone-number-authentication-with-vue-js-does-not-work
// https://stackoverflow.com/questions/51285008/firebase-recaptchaverifier-clear-has-no-effect
/* import Vue from 'vue'; */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Profile from "../components/UserProfile.vue";

export default {
  name: 'SignIn',
  components: {
    Profile
  },
  data() {
    return {
      // UI States
      showNumberInput: true,
      showCodeInput: false,
      showNewUser: false,
      user: null,

      // Forms
      numberInputForm: {
        number: '',
        code: ''
      },

      // Buttons
      getSignInCodeButton: {
        text: 'Obtenir le code',
      },
      signInButton: {
        text: 'Se connecter',
      },
    };
  },

  mounted() {

  firebase.auth().languageCode = 'fr';

  document.querySelector('.form-group input').focus()

    // Start Firebase invisible reCAPTCHA verifier

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': function() {
      return new Promise( (resolve) => {

       console.log('recaptcha OK')

       //Instead of using 'return false', use reject()
       //Instead of using 'return' / 'return true', use resolve()
       resolve();

     }); //end promise
      }
    });

  },

  methods: {
    /**
     * Sends the user an SMS-verification code using Firebase auth
     *
     * @see https://firebase.google.com/docs/auth/web/phone-auth
     */

    isPhoneNumberValid() {
     var pattern = /^[0-9()-.\s]+$/;
     var phoneNumber = this.numberInputForm.number
     this.numberInputForm.number = phoneNumber.replace(/[^0-9+]/g, '').replace(/(\..*)\./g, '$1');
     let check = false;
     check = (phoneNumber.search(pattern) !== -1);
     if(!phoneNumber.startsWith("+") && !phoneNumber.startsWith("0")) check = false
     if(phoneNumber.length != 10 && phoneNumber.length != 13 && phoneNumber.length != 12) check = false
     if(check){
      if(phoneNumber.startsWith("0")) this.numberInputForm.number= '+33' + this.numberInputForm.number.substring(1)
      document.querySelector('#get-sign-in-code').disabled = false;
      }
     else
      document.querySelector('#get-sign-in-code').disabled = true;

     return check;
    },

    onSignInSubmit() {
      if (this.isPhoneNumberValid()) {
      // window.signingIn = true;
      // updateSignInButtonUI();


      this.getSignInCodeButton = {
        showSpinner: true,
        text: 'Envoi du SMS..',
        disabled: true,
      };

      var phoneNumber = this.numberInputForm.number
      var appVerifier = window.recaptchaVerifier;
      firebase.auth().signInWithPhoneNumber(phoneNumber , appVerifier)
          .then( (confirmationResult) => {
            window.confirmationResult = confirmationResult;
            window.signingIn = false;
            this.showCodeInput = true;
            this.getSignInCodeButton = {
              showSpinner: false,
              text: 'SMS envoyé',
              disabled: true,
            };
            console.log('sent', confirmationResult)
            document.querySelector('#get-sign-in-code').classList.add('disabled')
            document.querySelector('#sign-in-container').classList.add('hide')
            // updateSignInButtonUI();
            // updateVerificationCodeFormUI();
            // updateVerifyCodeButtonUI();
            // updateSignInFormUI();

          }).catch(function (error) {
            // Error; SMS not sent

            alert('Erreur "' + error.code + '"\n\n');
            document.querySelector('#get-sign-in-code').innerHTML = 'Obtenir le code';
            console.log('Error during signInWithPhoneNumber:\n\n'
                + error.code + '\n\n' + error.message);
            window.signingIn = false;
            // updateSignInFormUI();
            // updateSignInButtonUI();
          });
        }
      },
      onVerifyCodeSubmit () {

        if (this.numberInputForm.code!='') {
          window.verifyingCode = true;
          // updateVerifyCodeButtonUI();
          var code = this.numberInputForm.code;
          window.confirmationResult.confirm(code).then( (result) => {
            // User signed in successfully.
            var user = result.user;
            this.user = user;
            console.log(this.user)
            window.verifyingCode = false;
            window.confirmationResult = null;
            this.showNumberInput = false;
            this.showCodeInput = false;
            this.showNewUser = true;
            // updateVerificationCodeFormUI();
          }).catch(function (error) {
            // User couldn't sign in (bad verification code?)
            console.error('Error while checking the verification code', error);
            window.alert('Error while checking the verification code:\n\n'
                + error.code + '\n\n' + error.message);
            window.verifyingCode = false;
            // updateSignInButtonUI();
            // updateVerifyCodeButtonUI();
          });
        }

      }

  },
};

</script>
