<template>
  <div class="user screen-user">

    <header>
      <h1>
        <span v-if="$store.state.lookedAtUser">{{$store.state.lookedAtUser.displayName}}</span>
        <img class="icon" src="@/assets/icons/female.svg" v-if="gender=='f'" /><img class="icon" src="@/assets/icons/male.svg" v-if="gender=='m'" />
        &nbsp;<small><a href="#" @click.prevent="reindexUser">Réindexer</a></small>
      </h1>
      <div>
          <div>
          <a v-if="(isAdmin || isManager) && !isEditing" class="btn" @click.prevent="editUser" >Créditer</a>
          <span v-if="isEditing" class="buttons">
            <a class="btn" @click.prevent="credits=parseInt(credits)-1"><img class="icon" src="@/assets/icons/minus_.svg" /></a>
            <a class="btn" @click.prevent="credits=parseInt(credits)+1"><img class="icon" src="@/assets/icons/plus_.svg" /></a>
            <input type="number" v-model="credits" class="credits" disabled="disabled">
            <a class="btn" @click.prevent="saveCreditB()">Sauver</a>
          </span>
          <span v-if="!isEditing" :class="credits<2?'credits red':'credits'">
            {{credits}} <img class="icon" src="@/assets/icons/coin.svg" v-if="credits>1" /><img class="icon" src="@/assets/icons/coin_.svg" v-else />
          </span>
      </div>
      <div>
          <a v-if="(isAdmin || isManager) && !isEditingD" class="btn" @click.prevent="editUserD" >Créditer Duo</a>
          <span v-if="isEditingD" class="buttons">
            <a class="btn" @click.prevent="creditsD=parseInt(creditsD)-1"><img class="icon" src="@/assets/icons/minus_.svg" /></a>
            <a class="btn" @click.prevent="creditsD=parseInt(creditsD)+1"><img class="icon" src="@/assets/icons/plus_.svg" /></a>
            <input type="number" v-model="creditsD" class="credits" disabled="disabled">
            <a class="btn" @click.prevent="saveCreditDB()">Sauver</a>
          </span>
          <span v-if="!isEditingD" :class="creditsD<2?'credits red':'credits'">
            {{creditsD}} <img class="icon" src="@/assets/icons/coind.svg" v-if="credits>1" /><img class="icon" src="@/assets/icons/coind_.svg" v-else />
          </span>
          </div>
  </div>
    </header>
    <div v-if="(isAdmin || isManager)" class="headstats" style="margin-top: 0;">
      <div>
        <small>{{birthDate.split("-")[2]}}/{{birthDate.split("-")[1]}}/{{birthDate.split("-")[0]}} ({{age(birthDate)}})</small>
      </div>
      <div>
      <small>{{this.height}}CM</small>
      </div>
    </div>
    <div class="headstats" v-if="isAdmin||isManager">Facturation :
          <input type="text" v-model="bill"  @keyup.enter="saveStatsB(this)" :class="validating?'green':''" />

          <a class="btn" :href="'sms:'+phone" @click="saveCall(user)"><img class="icon" src="@/assets/icons/message_.svg" /></a>
          <router-link v-if="isAdmin||isManager" :to="'/message/'+uid" class="btn">Message</router-link>

    </div>
  <div class="userScreen">



    <div class="fiche" v-if="isAdmin || isManager">
      <div class="header">
        <h2>Fiche</h2>
        <a href="#" :class="saving?'green':''" @click.prevent="saveStatsF(note)" class="btn">Enregistrer</a>
      </div>
      
      <div class="hs-input" style="flex-direction: column">
            <textarea v-model="fiche" placeholder=""></textarea>
        </div>
    </div>

    <div v-if="(isAdmin || isManager)" class="headstats">
      <div>
        <button :class="lastCall(call)?'btn switch':'btn switch active'" @click="call = false;saveStatsB()" :href="'tel:'+phone">Appel</button>
      </div>
      <div>
        <input v-model="note" @keyup.enter="saveStatsB(this)" />
      </div>
      <div>
        <button :class="gourde?'btn switch active':'btn switch'" @click="gourde=!gourde;saveStatsB()">Gourde</button> <button :class="xBody?'btn switch active':'btn switch'" @click="xBody=!xBody;saveStatsB()">XBody</button>
      </div>
    </div>


  
 
  <!-- div v-if="this.$store.state.lookedAtCreditsHistory!=null && typeof this.$store.state.lookedAtCreditsHistory.solo !='undefined'">
    <h3>Crédits Solo</h3> 
    <p v-for="date, datek in this.$store.state.lookedAtCreditsHistory.solo" v-bind:key="datek"> 
      <strong>{{ datek.slice( 8, 10) }}/{{ datek.slice( 5, 7) }}/{{ datek.substring( 0, 4) }}</strong><br />
      <span v-for="time, timek in this.$store.state.lookedAtCreditsHistory.solo[datek]" v-bind:key="timek">
        {{ this.$store.state.lookedAtCreditsHistory.solo[datek][timek] }} à {{ timek }}<br />
      </span>
    </p>
  </div> 
  <div v-if="this.$store.state.lookedAtCreditsHistory!=null && typeof this.$store.state.lookedAtCreditsHistory.duo !='undefined'">
    <h3>Crédits Duo</h3>
    <p v-for="date, datek in this.$store.state.lookedAtCreditsHistory.duo" v-bind:key="datek"> 
      <strong>{{ datek.slice( 8, 10) }}/{{ datek.slice( 5, 7) }}/{{ datek.substring( 0, 4) }}</strong><br />
      <span v-for="time, timek in this.$store.state.lookedAtCreditsHistory.duo[datek]" v-bind:key="timek">
        {{ this.$store.state.lookedAtCreditsHistory.duo[datek][timek] }} à {{ timek }}<br />
      </span>
    </p>
  </div --> 
    <!-- div>

      <div class="header">
        <router-link v-if="isAdmin||isManager" :to="'/measurements/'+uid" class="btn">Mesures</router-link>
        <router-link :to="'/measurement/'+uid" v-if="isAdmin||isManager" class="btn"><img class="icon" src="@/assets/icons/plus_.svg" /></router-link>
      </div>
      <div class="table-container">
      <table>
        <tr>
          <td></td>
          <th v-for="(measurement, key) in SelectedMeasurements" v-bind:key="measurement.id">
            <router-link :to="'/measurement/'+uid+'/'+key" v-if="isAdmin||isManager">
              {{local(key)}}
            </router-link>
            <span v-else>{{local(key)}}</span>
          </th>
          <th></th>
        </tr>
        <tr>
          <th>Poids</th>
          <td v-for="(measurement) in SelectedMeasurements" v-bind:key="measurement.id">
            {{measurement.w}}
          </td>
          <td>-</td>
        </tr>
        <tr>
          <th>Masse grasse</th>
          <td v-for="(measurement) in SelectedMeasurements" v-bind:key="measurement.id">
            {{measurement.bf}}
          </td>
          <td>-</td>
        </tr>
        <tr>
          <th>Volume hydrique</th>
          <td v-for="(measurement) in SelectedMeasurements" v-bind:key="measurement.id">
            {{measurement.bw}}
          </td>
          <td>-</td>
        </tr>
        <tr>
          <th>Masse musculaire</th>
          <td v-for="(measurement) in SelectedMeasurements" v-bind:key="measurement.id">
            {{measurement.mm}}
          </td>
          <td>-</td>
        </tr>
      </table>
          </div>

    </div>

    <div>
      <div class="header">
        <router-link v-if="isAdmin||isManager" :to="'/trainings/'+uid" class="btn">Entraînements</router-link>
        <router-link v-if="isAdmin||isManager" :to="'/training/'+uid" class="btn"><img class="icon" src="@/assets/icons/plus_.svg" /></router-link>
      </div>
      <div class="table-container">
          <table>
            <tr>
              <th>date</th>
              <td>✯</td>
              <td v-for="(training, key) in Trainings" v-bind:key="training.id">
                <router-link :to="'/training/'+uid+'/'+key" v-if="isAdmin||isManager">
                  {{local(key)}}
                </router-link>
                <span v-else>{{local(key)}}</span>
              </td>
              <td></td>
            </tr>
            <tr v-for="(training, key) in Results" v-bind:key="training.id">
              <th>{{Equipments[key].name}}</th>
              <td v-if="typeof Equipments[key].total != 'undefined'">{{parseFloat((0+parseFloat(Equipments[key].total)).toFixed(2))}}</td>
              <td v-else></td>
              <td v-for="(training) in Trainings" v-bind:key="training.id">{{training[key]}}</td>
              <td>-</td>
            </tr>
          </table>
      </div>

    </div -->


    <div v-if="isAdmin||isManager">

<table class="seances"><tr>
<td>
<div v-if="Object.keys(NextBookings).length>0">
<div class='header'>
  
  <h2>Prochaine Séance <small><a href="#" @click.prevent="reindexUsersBookings">Réindexer</a></small></h2>
</div>
<div>
  <div>
    <template v-for="(booking, keyb) in NextBookings">
      <div v-for="(time, keyt) in booking" v-bind:key="time.id" class="histo">
        <div class="time">
        {{local(keyb)}} à {{keyt}}
        </div>
          <span v-for="(ccoach,keycoach) in NextBookings[keyb][keyt]" v-bind:key="ccoach.id">
            <span v-if="Coachs[keycoach]">{{Coachs[keycoach].displayName}}
            </span>
            <span v-if="time[keycoach]['confirmed']">Confirmé</span>
            <span v-else>Non confirmé</span>
          </span>

        </div>
    </template>
  </div>
</div>
</div>
</td>
<td>
<div>
<div class='header'>
<h2>Dernière Séance</h2>
</div>
<div v-if="LastBookings">
<div>
  <template v-for="(booking, keyb) in LastBookings">
    <div v-for="(time, keyt) in booking" v-bind:key="time.id" class="histo">
      <div class="time">
      {{local(keyb)}} à {{keyt}}
      </div>
        <span v-for="(ccoach,keycoach) in LastBookings[keyb][keyt]" v-bind:key="ccoach.id">
          <span v-if="Coachs[keycoach]">{{Coachs[keycoach].displayName}}</span>
        </span>
    </div>
  </template>
</div>
</div>
</div>
</td></tr></table>

<div v-if="isAdmin||isManager" class="header">
<p> &nbsp;</p>
</div>

</div>

    <div class="user-actions">
      <router-link :to="'/history/'+uid" class="small">Voir l'historique des séances <span v-if="this.$store.state.userLogs!=null">({{Object.keys(this.$store.state.userLogs).length}})</span> <img class="icon" src="@/assets/icons/wall-clock.svg" /></router-link>
      <router-link :to="'/credits/'+uid" class="small">Voir l'historique des crédits <img class="icon" src="@/assets/icons/wall-clock.svg" /></router-link>
      <router-link  v-if="isAdmin" :to="'/profile/'+uid" class="small">Modifier le profil <img class="icon" src="@/assets/icons/edit.svg" /></router-link>
      <a @click="archiveUser(uid)" class="small" v-if="role==3 && isAdmin">Supprimer le profil<img class="icon" src="@/assets/icons/block.svg" /></a>
    </div>


  </div>


    </div>
</template>
<script>
import firebase from "firebase/compat/app";
import { mapActions, mapGetters } from "vuex";
/* import 'vue-slider-component/theme/antd.css'; */ /* https://nightcatsama.github.io/vue-slider-component/#/ */

export default {
  props: ["user"],
  components: {
  },
  data() {
    return {
      graphs: {
      weight: { name: "Poids", icon: "weighing-scale_", id: "w", min:40,max: 160 },
        bodyfat: { name: "Masse grasse", icon: "trans-fat_", id: "bf", min:0, max: 50 },
        bodywater: { name: "Volume hydrique", icon: "drops_", id: "bw", min:0, max: 50 },
        musclemass: { name: "Masse sèche", icon: "muscle_", id: "mm", min:0, max: 50 }
      },
      gstats: [
        { label: "A", value: 60 },
        { label: "B", value: 60 },
        { label: "C", value: 60 },
        { label: "D", value: 60 }
      ],
      validating: false,
      currentGraph: 'weight',
      currentUser: null,
      isEditing: false,
      isEditingD: false,
      name: '',
      saving: false,
      email: '',
      phone: '',
      fiche:null,
      gender: '',
      height: '',
      userBookings: {},
      credits: 0,
      creditsD: 0,
      role:3,
      birthDate: '',
      call: {seconds : 0, nanoseconds: 0},
      gourde: false,
      xBody: false,
      note: '',
      bill: '',
      lastLoginAt: '',
      createdAt: '',
      stats: [],
      validationErrors: [],
      uid: null,
      valueSelected: null,
      data: ['a', 'b', 'c', 'd', 'e', 'f', 'g'],
      options: {
dotSize: 14,
width: 'auto',
height: 4,
contained: false,
direction: 'ltr',
dataLabel: 'label',
dataValue: 'value',
min: 0,
max: 100,
interval: 1,
disabled: false,
clickable: true,
duration: 0.5,
adsorb: false,
lazy: false,
tooltip: 'none',
tooltipPlacement: 'top',
tooltipFormatter: void 0,
useKeyboard: false,
keydownHook: null,
dragOnClick: false,
enableCross: true,
fixed: true,
order: true,
marks: true,
dotOptions: void 0,
dotAttrs: void 0,
process: true,
dotStyle: void 0,
railStyle: void 0,
processStyle: void 0,
tooltipStyle: void 0,
stepStyle: void 0,
stepActiveStyle: void 0,
labelStyle: void 0,
labelActiveStyle: void 0,
      }
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'isUserAuth', 'isAdmin','isManager', 'getServerTime']),

    Equipments() {

        const equip = this.$store.state.equipments;

        if(this.$store.state.userTrainings!=null)
        Object.keys(this.$store.state.userTrainings).forEach(key => {

          for(let e in equip) {
              if(typeof this.$store.state.userTrainings[key][e] !="undefined") {

                if(typeof this.$store.state.equipments[e]['total'] == "undefined" )
                  this.$store.state.equipments[e]['total'] = parseFloat(this.$store.state.userTrainings[key][e]);
                else
                  switch(equip[e].operator) {
                    case '+':
                      this.$store.state.equipments[e]['total'] = parseFloat(this.$store.state.equipments[e]['total']) + parseFloat(this.$store.state.userTrainings[key][e]);
                    break;
                    case '%':
                      this.$store.state.equipments[e]['total'] = (this.$store.state.equipments[e]['total'] + parseFloat(this.$store.state.userTrainings[key][e]))/2;
                    break;
                    case '>':
                      if(this.$store.state.equipments[e]['total'] < parseFloat(this.$store.state.userTrainings[key][e])) this.$store.state.equipments[e]['total'] = parseFloat(this.$store.state.userTrainings[key][e]);
                    break;
                    case '<':
                      if(this.$store.state.equipments[e]['total'] > parseFloat(this.$store.state.userTrainings[key][e])) this.$store.state.equipments[e]['total'] = parseFloat(this.$store.state.userTrainings[key][e]);
                    break;
                  }

              }
          }

        });


      return this.$store.state.equipments;

    },

        LastBookings() {

          const trainings = [];

          if(this.$store.state.userLogs==null) {
            return trainings;
          } else {

            let ordered1 = null;
            if(typeof this.$store.state.userLogs!="undefined" ) {
              
          for(let booking in this.$store.state.userLogs) {
            let bookingdata = false;
            bookingdata = this.$store.state.userLogs[booking][Object.keys(this.$store.state.userLogs[booking])[0]][Object.keys(this.$store.state.userLogs[booking][Object.keys(this.$store.state.userLogs[booking])[0]])[0]];
            
            if(typeof bookingdata != "undefined" && bookingdata.user != this.uid && bookingdata.user2 != this.uid)
              delete this.$store.state.userLogs[booking];
          }
             ordered1 = Object.keys(this.$store.state.userLogs).sort().reverse().reduce(
              (obj, key) => {

                obj[key] = this.$store.state.userLogs[key];
                return obj;
              },
              {}
            );
            }



            let ordered = Object.assign({},ordered1);

            let date = null;
            const today = this.getServerTime;
            today.setHours(0,0,0,0);

            const ordered3 = Object.keys(ordered).sort().reverse().reduce(
              (obj, key) => {
                date = new Date(key);
                date.setHours(0,0,0,0);

                if(date < today)
                  obj[key] = ordered[key];
                return obj;

              },
              {}
            );

            let final = {};
            final[Object.keys(ordered3)[0]] = ordered3[Object.keys(ordered3)[0]];

            return final;
          }

    },
    NextBookings() {

      const trainings = [];

      if(this.$store.state.lookedAtBookings==null) {
        return trainings;
      } else {


        let ordered1 = null;
        if(typeof this.$store.state.lookedAtBookings!="undefined" )
         ordered1 = Object.keys(this.$store.state.lookedAtBookings).sort().reverse().reduce(
          (obj, key) => {
            console.log('key', obj[key]);
            obj[key] = this.$store.state.lookedAtBookings[key];
            return obj;
          },
          {}
        );

        let ordered = Object.assign({},ordered1);

        let date = null;
        const today = this.getServerTime;
        today.setHours(0,0,0,0);

        const ordered3 = Object.keys(ordered).sort().reverse().reduce(
          (obj, key) => {
            date = new Date(key);
            date.setHours(0,0,0,0);


            if(typeof key != "undefined")
            if(date >= today)
              obj[key] = ordered[key];
            return obj;

          },
          {}
        );
        let final = {};
        if(typeof Object.keys(ordered3)[0] !="undefined") final[Object.keys(ordered3)[0]] = ordered3[Object.keys(ordered3)[0]];
        if(typeof Object.keys(ordered3)[1] !="undefined") final[Object.keys(ordered3)[1]] = ordered3[Object.keys(ordered3)[1]];
        if(typeof Object.keys(ordered3)[2] !="undefined") final[Object.keys(ordered3)[2]] = ordered3[Object.keys(ordered3)[2]];
        if(typeof Object.keys(ordered3)[3] !="undefined") final[Object.keys(ordered3)[3]] = ordered3[Object.keys(ordered3)[3]];
        if(typeof Object.keys(ordered3)[4] !="undefined") final[Object.keys(ordered3)[4]] = ordered3[Object.keys(ordered3)[4]];
        if(typeof Object.keys(ordered3)[5] !="undefined") final[Object.keys(ordered3)[5]] = ordered3[Object.keys(ordered3)[5]];
        if(typeof Object.keys(ordered3)[6] !="undefined") final[Object.keys(ordered3)[6]] = ordered3[Object.keys(ordered3)[6]];
        if(typeof Object.keys(ordered3)[7] !="undefined") final[Object.keys(ordered3)[7]] = ordered3[Object.keys(ordered3)[7]];
        if(typeof Object.keys(ordered3)[8] !="undefined") final[Object.keys(ordered3)[8]] = ordered3[Object.keys(ordered3)[8]];
        if(typeof Object.keys(ordered3)[9] !="undefined") final[Object.keys(ordered3)[9]] = ordered3[Object.keys(ordered3)[9]];
        if(typeof Object.keys(ordered3)[10] !="undefined") final[Object.keys(ordered3)[10]] = ordered3[Object.keys(ordered3)[10]];
        if(typeof Object.keys(ordered3)[11] !="undefined") final[Object.keys(ordered3)[11]] = ordered3[Object.keys(ordered3)[11]];
        if(typeof Object.keys(ordered3)[12] !="undefined") final[Object.keys(ordered3)[12]] = ordered3[Object.keys(ordered3)[12]];
        if(typeof Object.keys(ordered3)[13] !="undefined") final[Object.keys(ordered3)[13]] = ordered3[Object.keys(ordered3)[13]];
        if(typeof Object.keys(ordered3)[14] !="undefined") final[Object.keys(ordered3)[14]] = ordered3[Object.keys(ordered3)[14]];
        if(typeof Object.keys(ordered3)[15] !="undefined") final[Object.keys(ordered3)[15]] = ordered3[Object.keys(ordered3)[15]];
        if(typeof Object.keys(ordered3)[16] !="undefined") final[Object.keys(ordered3)[16]] = ordered3[Object.keys(ordered3)[16]];
        if(typeof Object.keys(ordered3)[17] !="undefined") final[Object.keys(ordered3)[17]] = ordered3[Object.keys(ordered3)[17]];
        if(typeof Object.keys(ordered3)[18] !="undefined") final[Object.keys(ordered3)[18]] = ordered3[Object.keys(ordered3)[18]];
        if(typeof Object.keys(ordered3)[19] !="undefined") final[Object.keys(ordered3)[19]] = ordered3[Object.keys(ordered3)[19]];
        if(typeof Object.keys(ordered3)[20] !="undefined") final[Object.keys(ordered3)[20]] = ordered3[Object.keys(ordered3)[20]];
        if(typeof Object.keys(ordered3)[21] !="undefined") final[Object.keys(ordered3)[21]] = ordered3[Object.keys(ordered3)[21]];
        if(typeof Object.keys(ordered3)[22] !="undefined") final[Object.keys(ordered3)[22]] = ordered3[Object.keys(ordered3)[22]];
        if(typeof Object.keys(ordered3)[23] !="undefined") final[Object.keys(ordered3)[23]] = ordered3[Object.keys(ordered3)[23]];
        if(typeof Object.keys(ordered3)[24] !="undefined") final[Object.keys(ordered3)[24]] = ordered3[Object.keys(ordered3)[24]];
        if(typeof Object.keys(ordered3)[25] !="undefined") final[Object.keys(ordered3)[25]] = ordered3[Object.keys(ordered3)[25]];
        if(typeof Object.keys(ordered3)[26] !="undefined") final[Object.keys(ordered3)[26]] = ordered3[Object.keys(ordered3)[26]];
        if(typeof Object.keys(ordered3)[27] !="undefined") final[Object.keys(ordered3)[27]] = ordered3[Object.keys(ordered3)[27]];
        if(typeof Object.keys(ordered3)[28] !="undefined") final[Object.keys(ordered3)[28]] = ordered3[Object.keys(ordered3)[28]];
        if(typeof Object.keys(ordered3)[29] !="undefined") final[Object.keys(ordered3)[29]] = ordered3[Object.keys(ordered3)[29]];
        if(typeof Object.keys(ordered3)[30] !="undefined") final[Object.keys(ordered3)[30]] = ordered3[Object.keys(ordered3)[30]];
        if(typeof Object.keys(ordered3)[31] !="undefined") final[Object.keys(ordered3)[31]] = ordered3[Object.keys(ordered3)[31]];

        console.log('ordered', ordered, ordered3);
        return final;
      }

    },

    Trainings() {
      const trainings = [];
      if(this.$store.state.userTrainings==null) {
        return trainings;
      } else {
      const ordered = Object.keys(this.$store.state.userTrainings).sort().reverse().reduce(
        (obj, key) => {
          obj[key] = this.$store.state.userTrainings[key];
          return obj;
        },
        {}
      );

      let arr = {};
      if(Object.keys(ordered).length>0) {
      arr[Object.keys(ordered)[0]] = ordered[Object.keys(ordered)[0]];
      /* arr[Object.keys(ordered)[1]] = ordered[Object.keys(ordered)[1]]; */
      }


      return arr;
      }
    },

    Results() {

      let chart = {};

      for(let equipment in this.Equipments) {
        chart[equipment] = {};
        for (let training in this.Trainings)
          if(typeof this.Trainings[training][equipment] != "undefined")
            chart[equipment][training] = this.Trainings[training][equipment];
      }

      let check = false;
      for(let eq in chart) {
        for(let training in this.Trainings) {
         if(typeof this.Trainings[training][eq] != "undefined") {
          check = true;
         }
        }

        if(!check) delete chart[eq];
      }


      const ordered = Object.keys(chart).sort().reduce(
        (obj, key) => {
          obj[key] = chart[key];
          return obj;
        },
        {}
      );

      return ordered;

    },

    value :  {
        get: function () {
          if(this.valueSelected==null) {
            const measurements = [];
            if(this.$store.state.userMeasurements==null) {
              return measurements;
            } else {
            const ordered = Object.keys(this.$store.state.userMeasurements).sort().reduce(
              (obj, key) => {
                obj[key] = this.$store.state.userMeasurements[key];
                return obj;
              },
              {}
            );
            let sliceLength = 3;
            if(Object.keys(ordered).length>5) sliceLength = 5;
            return Object.keys(ordered).slice(-sliceLength);
            }
          } else return this.valueSelected;
        },
        // setter
        set: function (newValue) {
          this.valueSelected = newValue;
        }
    },
    Measurements() {
      const measurements = [];
      if(this.$store.state.userMeasurements==null) {
        return measurements;
      } else {
      const ordered = Object.keys(this.$store.state.userMeasurements).sort().reduce(
        (obj, key) => {
          obj[key] = this.$store.state.userMeasurements[key];
          return obj;
        },
        {}
      );

      return ordered;
      }
    },

    Coachs() {


      if(this.$store.state.config!=null) {
        const ordered = Object.keys(this.$store.state.config.team).sort().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.config.team[key];
            return obj;
          },
          {}
        );

        return ordered;
      } return {};

    },
    SelectedMeasurements() {
      let Selected = JSON.parse(JSON.stringify(this.Measurements));
      for(let measurement in Selected) {
        if(!this.value.includes(measurement))
          delete Selected[measurement];
      }
      return Selected;
    },
    Total() {

      return true;
      /*

      let total = 0;
      let moy = 0;
      let max = 0;

      if(this.$store.state.userTrainings==null) {
        return 0;
      } else {

      Object.keys(this.$store.state.userTrainings).forEach(key => {

        if(typeof this.$store.state.userTrainings[key].c !="undefined")
          total = total + parseInt(this.$store.state.userTrainings[key].c)

        if(typeof this.$store.state.userTrainings[key].fc.moy !="undefined")
          if(moy!=0)
            moy = (moy + parseInt(this.$store.state.userTrainings[key].fc.moy))/2;
          else
            moy = parseInt(this.$store.state.userTrainings[key].fc.moy);

        if(typeof this.$store.state.userTrainings[key].fc.max !="undefined")
          if(parseInt(max)<parseInt(this.$store.state.userTrainings[key].fc.max))
            max = parseInt(his.$store.state.userTrainings[key].fc.max)

      });
      return {total: total, moy: moy, max:max};
      }
      */
    }
  },
  methods: {
    /* minMax() {

      let check = false;


      for(let graph in this.graphs) {
        let min = 9999;
        let max = 0;
        if(typeof this.Measurements != "undefined")
        for(let measurement in this.Measurements) {
          if(min>parseInt(this.Measurements[measurement][this.graphs[graph].id])) min = parseInt(this.Measurements[measurement][this.graphs[graph].id]);
          if(max<parseInt(this.Measurements[measurement][this.graphs[graph].id])) max = parseInt(this.Measurements[measurement][this.graphs[graph].id]);
          check = true;
        }
        this.graphs[graph].min = parseInt(min) - 1;
        this.graphs[graph].max = parseInt(max) + 1;
      }

      return check;

    }, */
    reindexUsersBookings() {
      let date = new Date();
      let newDate = date.setDate(date.getDate() + 90);
      console.log(new Date(newDate).toISOString().slice(0,10));
      
      this.$store.dispatch("reindexUserBookings", {day:new Date(newDate).toISOString().slice(0,10), user: this.uid});
    },
    archiveUser(user) {
      if(confirm("Supprimer cet utilisateur ?")){
        this.$store.dispatch("archiveUser", {user: user});
        console.log(user)
        setTimeout(()=>{this.$router.push('/users')},1000);
      }
    },
    lastCall(date) {
      if(typeof date == "undefined" ) return true;
      if(date == null ) return true;

      let d = new Date('1/1/1970');
      if(typeof date.seconds != "undefined")
        d = new Date(date.seconds * 1000);


      let monday =  this.getServerTime;
      monday.setHours(0);
      monday.setMinutes(0);
      monday.setSeconds(1);
      // Sunday - Saturday : 0 - 6
      let day = monday.getDay();
      if (day == 0) day = 6; else day = day -1;
      monday.setDate(monday.getDate()-day);

      var seconds = (d.getTime() - monday.getTime()) / 1000;

      if(seconds>0)
        return false;
      else
        return true;

    },

      getIconUrl(icn) {
        var images = require.context('../assets/icons/', false, /\.svg$/)
        return images('./' + icn + ".svg")
      },
    local(day) {
      const d = new Date(day+'T12:00:00');
      const options = { month: 'numeric', day: 'numeric', year: '2-digit' };

      return d.toLocaleDateString('fr-FR', options);
    },
    removeUser() {
      console.log('remove');
    },
    saveBills(e) {

    console.log(e);

    const data = {};
    data.user = this.uid;
    data.bill = this.bill;

    console.log('data',data)
    this.saveBill(data);

    this.bill = '';

    },
    saveNote() {
      console.log("test");
      this.saveStatsB();
    },
    saveStatsF() {
      
      this.saving = true;
      setTimeout(()=>{
        this.saving = false;
      },1600);
      
      const data = {};

      if(typeof this.fiche != "undefined")
      data.fiche = this.fiche;
      else data.fiche = null;

      data.user = this.uid;
      data.gourde = this.gourde;
      if(typeof this.xBody != "undefined")
      data.xBody = this.xBody;
      else data.xBody = null;

      if(typeof this.note != "undefined")
      data.note = this.note;
      else data.note = null;

      if(typeof this.bill != "undefined")
      data.bill = this.bill;
      else data.bill = null;


      if(this.call===false) {
        this.call = firebase.firestore.Timestamp.fromDate(this.getServerTime);
        data.call =  firebase.firestore.Timestamp.fromDate(this.getServerTime);
      } else data.call = this.call;

      if(typeof data.call == "undefined") data.call = null;

      console.log('data',data)
      this.saveStats(data);
    },
    saveStatsB(e) {

      console.log(e, "test");

      this.validating = true;
      setTimeout(()=>{
        this.validating = false;
      },1600);

      const data = {};
      data.user = this.uid;
      data.gourde = this.gourde;
      if(typeof this.xBody != "undefined")
      data.xBody = this.xBody;
      else data.xBody = null;

      if(typeof this.note != "undefined")
      data.note = this.note;
      else data.note = null;

      if(typeof this.bill != "undefined")
      data.bill = this.bill;
      else data.bill = null;

      if(typeof this.fiche != "undefined")
      data.fiche = this.fiche;
      else data.fiche = null;

      if(this.call===false) {
        this.call = firebase.firestore.Timestamp.fromDate(this.getServerTime);
        data.call =  firebase.firestore.Timestamp.fromDate(this.getServerTime);
      } else data.call = this.call;

      if(typeof data.call == "undefined") data.call = null;

      console.log('data',data)
      this.saveStats(data);

    },
    saveCreditB() {
      const data = {};
      data.user = this.uid;
      data.credits = this.credits;
      data.admin = this.$store.state.user.uid;
      this.saveCredit(data)
      this.isEditing = false;
    },
    saveCreditDB() {
      const data = {};
      data.user = this.uid;
      data.creditsD = this.creditsD;
      data.admin = this.$store.state.user.uid;
      this.saveCreditD(data)
      this.isEditingD = false;
    },

    editUser() {
      this.isEditing = true;
    },
    editUserD() {
      this.isEditingD = true;
    },
    updateCredits() {
      this.isEditing = false;
      console.log('update');
    },
    updateCreditsD() {
      this.isEditingD = false;
      console.log('update');
    },
    updateUser() {
      this.isEditing = false;
      console.log('update');
    },
   resetError() {
     this.validationErrors = [];
   },
   age(sec) {
     var ageDifMs = Date.now() - new Date(sec);
     var ageDate = new Date(ageDifMs); // miliseconds from epoch
     return Math.abs(ageDate.getUTCFullYear() - 1970);
   },
   polyline() {
     const container = [];

     for(let graph in this.graphs) {
       const poly = [];
       let i = 0;
       poly.push('50,400');
       if(typeof this.SelectedMeasurements != "undefined")
       for(let measurement in this.SelectedMeasurements) {

         const x = 50 + 640/(Object.keys(this.SelectedMeasurements).length-1) * i++
         const y = 400 - (400/(this.graphs[graph].max - this.graphs[graph].min) ) * (parseFloat(this.SelectedMeasurements[measurement][this.graphs[graph].id]) - this.graphs[graph].min)
         poly.push(x + "," + y);

       }
       poly.push('690,400');

       container[this.graphs[graph].id] = poly;
     }

     return container;
   },
  validate() {
    // Clear the errors befo$re we validate again
    this.resetError();

    /*
    // email validation
    if (!this.email) {
      this.validationErrors.push("<strong>E-mail</strong> cannot be empty.");
    }
    if (/.+@.+/.test(this.email) != true) {
      this.validationErrors.push("<strong>E-mail</strong> must be valid.");
    }
    // password validation

    if (!this.password) {
      this.validationErrors.push("<strong>Password</strong> cannot be empty");
    }
    if (/.{6,}/.test(this.password) != true) {
      this.validationErrors.push(
        "<strong>Password</strong> must be at least 6 characters long"
      );
    }

    if (!(this.password === this.passwordRepeat)) {
      this.validationErrors.push("<strong>Passwords</strong> did not match");
    }
    */

    // when valid then sign in
    if (this.validationErrors.length <= 0) {

        const user = firebase.auth().currentUser;
        const dateTime = new Date(this.birthDate).getTime();

        user.updateProfile({
          displayName: this.name,
        }).then(() => {
          console.log("Update successful.")
        }
        ).catch(() => {
            console.log("An error happened.")
        });

        const upUser = {};
        upUser.uid = user.uid
        upUser.displayName = user.displayName
        upUser.gender = this.gender
        upUser.height = this.height
        upUser.credits = this.credits
        if(typeof this.creditsD != "undefined")
        upUser.creditsD = this.creditsD
        upUser.role = this.role
        upUser.birthDate = {seconds : dateTime/1000, nanoseconds: 0}
        upUser.stats = this.stats
        this.$store.dispatch("updateUser", upUser);
      }
      
    },
    reindexUser() {
        this.$store.dispatch("reindexUser", this.uid);
    },
    ...mapActions([
      'saveCredit', 'saveCreditD', 'saveStats', 'saveBill'
    ]),
  },
  updated() {
    if(this.currentUser == null && this.$store.state.lookedAtUser !=null) {
    const currentUser = this.$store.state.lookedAtUser;
    this.currentUser = currentUser;
    if(currentUser != null) {
      this.name = currentUser.displayName,
      this.email = currentUser.email;
      this.phone = currentUser.phoneNumber;
      if(typeof currentUser.birthDate != "undefined") {
        this.gender = currentUser.gender;
        this.credits = currentUser.credits;
        if(typeof currentUser.creditsD != "undefined")
        this.creditsD = currentUser.creditsD;
        if(this.creditsD == null) this.creditsD = 0;
        this.role = currentUser.role;
        this.height = currentUser.height;
        const parsedTimestamp = new Date(currentUser.birthDate.seconds * 1000);
        const month = parsedTimestamp.getMonth()+1;
        const date = parsedTimestamp.getDate();
        this.birthDate =   parsedTimestamp.getFullYear()+ '-' + month.toString().padStart(2,0) + '-' + date.toString().padStart(2, '0');
        this.gourde = !!currentUser.gourde;
        this.xBody = !!currentUser.xBody;
        this.note = currentUser.note;
        this.fiche = currentUser.fiche;
        this.bill = currentUser.bill;
        this.call = currentUser.call;
        this.stats = currentUser.stats;
      }
    }
    }
  },
  mounted() {
    if(this.currentUser == null && this.$store.state.lookedAtUser !=null) {
    const currentUser = this.$store.state.lookedAtUser;
    console.log('cur',currentUser)
    this.currentUser = currentUser;
    if(currentUser != null) {
      this.name = currentUser.displayName,
      this.email = currentUser.email;
      this.phone = currentUser.phoneNumber;
      if(typeof currentUser.birthDate != "undefined") {
        this.userBookings = currentUser.bookings;
        this.gender = currentUser.gender;
        this.credits = currentUser.credits;
        if(typeof currentUser.creditsD != "undefined")
        this.creditsD = currentUser.creditsD;
        if(this.creditsD == null) this.creditsD = 0;
        this.role = currentUser.role;
        this.height = currentUser.height;
        const parsedTimestamp = new Date(currentUser.birthDate.seconds * 1000);
        const month = parsedTimestamp.getMonth()+1;
        const date = parsedTimestamp.getDate();
        this.birthDate =   parsedTimestamp.getFullYear()+ '-' + month.toString().padStart(2,0) + '-' + date.toString().padStart(2, '0');
        this.gourde = !!currentUser.gourde;
        this.xBody = !!currentUser.xBody;
        this.note = currentUser.note;
        this.fiche = currentUser.fiche;
        this.bill = currentUser.bill;
        this.call = currentUser.call;
        this.stats = currentUser.stats;
      }
    }
    }
    /*
    setTimeout(()=>{
      this.minMax();
    }, 400);
    */
  },
  created() {
    if(typeof this.user == "undefined")
      this.uid = this.$route.params.uid;
    else
      this.uid = this.user;

    this.$store.dispatch("bindUser", {user : this.uid});
    this.$store.dispatch("bindUserCredits", {user : this.uid});
    this.$store.dispatch("bindUserCreditsHistory", {user : this.uid});
    this.$store.dispatch("bindUserBookings", {user : this.uid});
    this.$store.dispatch("bindUserHistory", {user : this.uid});
    this.$store.dispatch("bindMeasurements", {user : this.uid});
    this.$store.dispatch("bindTrainings", {user : this.uid});
    this.$store.dispatch("bindEquipments");

  }
};
</script>
