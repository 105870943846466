<template>
  <div :class="!disabled?'datepicker':'datepicker disabled'">
      <!-- select id="datepicked" v-model="iterator" style="font-family: 'Courier New'" @change="updateValue" :disabled="disabled">
          <option v-for="index in 30" :value="index" v-bind:key="index" >
            {{getWeekDay(getDate(index)).charAt(0).toUpperCase()}} {{new Date(getDate(index)).toLocaleDateString("fr-Fr")}}
          </option>
      </select -->

      <div class="row-week row-title">
        <div class="column-day">L</div><div class="column-day">M</div><div class="column-day">M</div><div class="column-day">J</div><div class="column-day">V</div><div class="column-day">S</div><div class="column-day">D</div>
      </div>

      <div v-for="w in 5" :value="w" v-bind:key="w" class="row-week">

        <div v-for="empty in (getDayOfTheWeekAbs(getDate(1)) * (w==1) )" :value="empty" v-bind:key="empty" class="column-day empty">
          {{emptyDate(w, empty)}}
        </div>

        <div
        v-for="d in 7 - ((w==1) * getDayOfTheWeek(getDate(1),w))"
        :value="d"
        v-bind:key="d"
        :class="((w-1)*7 +d - ((w!=1) * getDayOfTheWeek(getDate(1),w)))==iterator?(saturday&&w==1?'column-day column-active sat':'column-day column-active'):(saturday&&w==1?'column-day sat':'column-day')"
        @click.prevent="iterator = ((w-1)*7 - ((w!=1) * getDayOfTheWeek(getDate(1),w)) ) +d;updateValue(w)">
          {{weekDate(w,d)}}
        </div>

      </div>


   </div>
</template>

<script>
import {mapGetters } from "vuex";

  export default {
    name: 'DatePicker',
    template: 'DatePicker',
    props: {
        modelValue: String,
        disabled : Boolean
    },
    created() {
      this.day = this.getServerTime;
    },

     data() {
       return {
         day: new Date(),
         iterator : 1,
         saturday : false
       };
     },
    computed: {
      ...mapGetters(['getServerTime']),
    },
    methods: {
      getDayOfTheWeekAbs(date) {
        let day  = (new Date(date).getDay()) - 1;
        if(day<=0) day = 0;
        if(day==-1) day = 0;
        return  day;
      },
      getDayOfTheWeek(date, w) {
        let day  = (new Date(date).getDay()) - 1;
        if(w==1 && day==-1) day = 0;
        return  day;
      },
      getDate (index) {
        index = parseInt(index);
        let dateTmp = new Date();

        dateTmp = dateTmp.setDate(dateTmp.getDate() + index);
        return dateTmp;
      },

      emptyDate(w, empty) {
        return new Date(this.getDate((w-1)*7 + empty - this.getDayOfTheWeek(this.getDate(1),w))).toLocaleDateString("fr-Fr", {day:"numeric"});
      },

      weekDate(w, d) {
        let saturday = new Date();
        saturday = saturday.getDay()==6;
        let date = this.getDate((w-1)*7 + d- ((w!=1) * this.getDayOfTheWeek(this.getDate(1),w)) );
        if(saturday && w==1) date = date + (1 * 24 * 60 * 60 * 1000);
        return new Date(date).toLocaleDateString("fr-Fr", {day:"numeric"});
      },
       getWeekDay(dateStr) {
        var date = new Date(dateStr);
        return date.toLocaleDateString('fr-Fr', { weekday: 'long' });
      },
      changeValue(){
        let date = this.day;
        date.setDate(date.getDate() + this.iterator);
        date = new Date(date).toISOString().slice(0, 10);
        this.$emit('change', date);
      },
      unbook (){
        this.$emit('unbook');
      },
      updateValue(w) {

          let saturday = new Date();
          saturday = saturday.getDay()==6;
          let date = 0;
          if(saturday && w==1) date = date + (1 * 24 * 60 * 60 * 1000);

          let newDate = new Date(this.getServerTime.getTime()+(this.iterator*24*60*60*1000)+ date);

          let result ='';
          result = newDate.toISOString().slice(0, 10);
          this.$emit('update:modelValue', result);
          this.$emit('change', result);
        }
    },
    watch: {
    }
  };
</script>
